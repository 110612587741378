import React from "react"
import "./jumbotron.scss"
import { Title } from "../Title/title"
import { Subtitle } from "../Subtitle/subTitle"
import Wrapper from "../Wrapper/wrapper"
import classNames from "classnames"

export const Jumbotron = props => {
  return (
    <div className="m-jumbotron-home">
      <div className="m-jumbotron-home-content">
        <Title hero content={props.title}/>
        <Subtitle hero content={props.subtitle}/>
        <Features content={props.features}/>
      </div>
    </div>
  )
}

export const JumbotronSimple = props => {
  const classnames = classNames("m-jumbotron-simple", {
    // size
    "m-jumbotron-simple--static": props.static,
  });
  const wrapperClassNames = classNames({
    'legal': props.legal,
    'contact': props.contact,
  });
  return (
    <div className={classnames}>
      <div className="m-jumbotron-simple-content">
        <Wrapper classNames={props.legal ? `legal` : undefined}>
          <Title hero center content={props.title}/>
          {props.subtitle && <Subtitle hero thin center content={props.subtitle}/>}
        </Wrapper>
      </div>
    </div>
  )
}

export const JumbotronPages = props => {
  const classnames = classNames("m-jumbotron-page", {
    // size
    "m-jumbotron-page--static": props.static,
  })
  return (
    <div className={classnames}>
      <div className="m-jumbotron-page-content">
        <Wrapper>
          <Title hero pages center content={props.title}/>
        </Wrapper>
      </div>
    </div>
  )
}

export const JumbotronPagesIcon = props => {
  const classnames = classNames("m-jumbotron-pages-icon", {
    // size
    "m-jumbotron-page--static": props.static,
  })
  return (
    <div className={classnames}>
      <div className="m-jumbotron-page-content">
        <Wrapper>
          <Title icon={props.icon} hero pages center content={props.title}/>
        </Wrapper>
      </div>
    </div>
  )
}
