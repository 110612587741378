import React from "react"
import classNames from "classnames"
import "./sections.scss"
import RichText from "../RichText/richText";
import { Title } from "../../Layout/Title/title";

export const Sections = props => {
  const classnames = classNames("m-section", {
    "m-sections__sides": props.sides,
  });
  return (
    <div className={classnames}>
      <div className="m-sections__main" data-qa={props.dataQa}>
        <div className="m-sections__title">
          <Title regular content={props.subtitle} />
        </div>
        <RichText content={props.main}/>
      </div>
      <div className="m-sections__aside" data-qa={props.dataQa}>
        <img src={props.img} />
      </div>
    </div>
  )
}
