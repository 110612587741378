import React, { Component } from "react"
import { graphql } from "gatsby"
import Layout from "../components/Layout/layout"
import Wrapper from "../components/Layout/Wrapper/wrapper"
import { JumbotronSimple } from "../components/Layout/Jumbotron/jumbotron"
import {Helmet} from "react-helmet";
import illustration from "../images/illustration/about-us.svg"
import { Sections } from "../components/Modules/Sections"

class AboutTemplate extends Component {
    render() {

        const page = this.props.data.contentfulAboutUs

        return (
            <Layout hideMenuButton={false}>
                <Helmet>
                    <title>{page.title}</title>
                    <meta name="description" content={page.metadescription} />
                </Helmet>
                <div>
                    <JumbotronSimple static title={page.title}/>
                    <Wrapper sides classNames={`about-us`}>
                        <Sections sides subtitle={page.subtitle} img={illustration} main={page.content.json} />
                    </Wrapper>
                </div>
            </Layout>
        )
    }
}

export default AboutTemplate

export const pageQuery = graphql`
    query($slug: String) {
        contentfulAboutUs(slug: { eq: $slug }) {
            title
            metadescription
            subtitle
            content {
                json
            }
        }
    }
`
